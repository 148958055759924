(function() {
    var $;

    $ = jQuery;

    function cookie(k) {
        return(document.cookie.match('(^|; )'+k+'=([^;]*)')||0)[2]
    }

    $.ajaxSetup({
        headers: {
            'X-XSRF-TOKEN': decodeURIComponent(cookie('XSRF-TOKEN'))
        }
    });

}).call(this);
